import React from 'react'
import * as dayjs from 'dayjs'
import * as eaw from 'eastasianwidth'
import * as AFHConvert from 'ascii-fullwidth-halfwidth-convert'
import { status } from './config/status'
import { Link } from 'components'
import { getMessages } from 'validation'

require('dayjs/locale/ja')
const googleCalendarDateFormat = 'YYYYMMDD[T]HHmmss[Z]+9:00'

/**
 * Group object with the same value
 *
 * @returns {Promise}
 * @param array
 * @param key
 */
export const groupBy = (array, key) => {
  return array.reduce((result, currentValue) => {
    ;(result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue)
    return result
  }, {})
}

export function formulateCurrentPage(currentPage, totalCount, pageSize) {
  let totalPages = Math.ceil(totalCount / pageSize)
  let newCurrentPage = currentPage

  if (totalPages === 0) {
    // There are no entries, set current page to none.
    newCurrentPage = 0
  } else if (totalPages < currentPage) {
    // The current page is out of bounds, return last page instead
    newCurrentPage = totalPages
  }

  return newCurrentPage
}

export function formulatePageResult(currentPage, totalCount, pageSize) {
  if (totalCount === 0) {
    return {
      pageFrom: 0,
      pageTo: 0,
    }
  } else {
    return {
      pageFrom: (currentPage - 1) * pageSize + 1,
      pageTo: currentPage * pageSize < totalCount ? currentPage * pageSize : totalCount,
    }
  }
}

export function formatDropdownData(data, name_field) {
  return data.map((item) => {
    return {
      id: parseInt(item.id),
      name: item.name || item[name_field],
    }
  })
}

export function getStatusByType(data, type, filterArray = null) {
  const allId = data.map(({ id }) => id)

  return data
    .filter((item) => item.type === type)
    .filter(({ id }) => (filterArray ? filterArray.includes(id) : allId.includes(id)))
}

export function formatJobGroupForm(data) {
  return {
    id: data?.id,
    client_id: {
      id: data?.client?.id,
      name: data?.client?.name,
    },
    project_id: {
      id: data?.project_id,
      name: data?.project_name,
    },
    client_user_id: {
      id: data?.client_user?.id,
      name: data?.client_user?.name,
      alias: data?.client_user?.alias,
    },
    setting: {
      id: data?.setting,
      name: data?.setting,
    },
    name: data?.name,
    alias: data?.alias,
    start_date: data?.start_date,
    end_date: data?.end_date,
    location: data?.location,
    min_salary: data?.min_salary?.toLocaleString(),
    max_salary: data?.max_salary?.toLocaleString(),
    no_of_workers: data?.no_of_workers?.toLocaleString(),
    details: data?.details,
    fees: data?.fees,
    allowances: data?.allowances,
    close_application_flag: data?.close_application_flag,
    email_address: data?.email_address,
    jobs: data?.jobs,
    registered_date: data?.registered_date,
  }
}

export function formatJobGroupDisplay(data) {
  return {
    alias: data?.alias,
    id: data?.id,
    name: data?.name,
    allowance_details: data?.allowances,
    client_alias: data?.client_name,
    client_name: data?.client?.name,
    client_user_name: data?.client_user_name,
    close_for_application: data?.close_application_flag,
    end_date: data?.end_date,
    fee_details: data?.fees,
    max_fee: '¥ ' + data?.max_salary?.toLocaleString(),
    min_fee: '¥ ' + data?.min_salary?.toLocaleString(),
    no_of_pos: data?.no_of_workers.toLocaleString() + ' 人',
    prefecture: data?.location,
    project_name: data?.project_name,
    start_date: data?.start_date,
    task_details: data?.details,
    visibility: data?.setting,
    status_name: data?.status_name,
    email_address: data?.email_address,
    jobs: data?.jobs,
    registered_date: data?.registered_date,
  }
}

export function formatJobForm(data) {
  return {
    id: data?.id,
    client_id: data?.client_id?.id,
    project_id: data?.project_id?.id,
    job_group_id: data?.job_group?.id,
    client_user_id: data?.client_user_id?.id,
    worker_id: data?.worker?.id,
    name: data?.name,
    date: data?.date,
    start_time: data?.start_time,
    end_time: data?.end_time,
    break_time: data?.break_time,
    zip_code: data?.zip_code,
    prefecture: data?.prefecture,
    municipality: data?.municipality,
    address_line_1: data?.address_line_1,
    address_line_2: data?.address_line_2,
    description: data?.description,
    salary: data?.salary,
    allowance: data?.allowance,
    toll_fee: data?.toll_fee,
    highway_fee: data?.highway_fee,
    parking_fee: data?.parking_fee,
    overnight_stay_fee: data?.overnight_stay_fee,
    other_fee: data?.other_fee,
    provided_to_worker_other: data?.provided_to_worker_other,
    items_provided_description: data?.items_provided_description,
    worker_tool_notes: data?.worker_tool_notes,
    attire: data?.attire,
    escalation_contact: data?.escalation_contact,
    invoice_generated: data?.invoice_generated,
    onsite_client_contact: data?.onsite_client_contact,
    onsite_name: data?.onsite_name,
    fellow: data?.fellow,
    remarks: data?.remarks,
    one_day_before_recipients: data?.one_day_before_recipients,
    departure_recipients: data?.departure_recipients,
    arrival_recipients: data?.arrival_recipients,
    clock_in_recipients: data?.clock_in_recipients,
    clock_out_recipients: data?.clock_out_recipients,
    goods_supplied_chk: data?.goods_supplied,
    goods_sent_to_worker_chk: data?.goods_sent_to_worker,
    goods_worker_bring_chk: data?.goods_worker_bring,
  }
}

export function formatJobFormDisplay(data) {
  return {
    id: data?.id,
    client_id: {
      id: data?.client?.id,
      name: data?.client?.name,
    },
    project_id: {
      id: data?.project?.id,
      name: data?.project?.name,
    },
    job_group: {
      id: data?.job_group?.id,
      name: data?.job_group_name,
    },
    client_user_id: {
      id: data?.client_user?.id,
      name: data?.client_user?.user?.name,
    },
    worker: {
      id: data?.worker?.id,
      name: data?.worker?.name,
    },
    name: data?.name,
    date: data?.date,
    start_time: data?.start_time,
    end_time: data?.end_time,
    break_time: data?.break_time,
    postal_code: data?.zip_code,
    prefecture: data?.prefecture,
    city: data?.municipality,
    address_line_1: data?.address_line_1,
    address_line_2: data?.address_line_2,
    description: data?.description,
    salary: data?.salary,
    allowance: data?.allowance,
    toll_fee: data?.toll_fee,
    highway_fee: data?.highway_fee,
    parking_fee: data?.parking_fee,
    overnight_stay_fee: data?.overnight_stay_fee,
    other_fee: data?.other_fee,
    provided_to_worker_other: data?.provided_to_worker_other,
    items_provided_description: data?.items_provided_description,
    worker_tool_notes: data?.worker_tool_notes,
    attire: data?.attire,
    escalation_contact: data?.escalation_contact,
    invoice_generated: data?.invoice_generated,
    onsite_client_contact: data?.onsite_client_contact,
    onsite_name: data?.onsite_name,
    fellow: data?.fellow,
    remarks: data?.remarks,
    one_day_before_recipients_flag: data?.one_day_before_recipients_flag,
    departure_recipients_flag: data?.departure_recipients_flag,
    arrival_recipients_flag: data?.arrival_recipients_flag,
    clock_in_recipients_flag: data?.clock_in_recipients_flag,
    clock_out_recipients_flag: data?.clock_out_recipients_flag,
    one_day_before_recipients: data?.one_day_before_recipients,
    departure_recipients: data?.departure_recipients,
    arrival_recipients: data?.arrival_recipients,
    clock_in_recipients: data?.clock_in_recipients,
    clock_out_recipients: data?.clock_out_recipients,
  }
}

export const getCurrentDate = () => {
  const current = new Date()
  return `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}`
}

export const formatAddress = (data) => {
  const postal_code = data?.zip_code || data?.postal_code || null
  const municipality = data?.municipality || data?.city || null
  const prefecture = data?.prefecture
    ? typeof data?.prefecture === 'string'
      ? data?.prefecture
      : data?.prefecture?.name
    : null
  let address =
    (postal_code ? postal_code + ' ' : '') +
    (prefecture ? prefecture + ' ' : '') +
    (municipality ? municipality + ' ' : '') +
    (data?.address_line_1 ? data?.address_line_1 + ' ' : '') +
    (data?.address_line_2 ? data?.address_line_2 + ' ' : '')

  return address
}

export function formatJobDetails(data) {
  const getProvidedDocuments = data?.provided_documents.map((value) => '- ' + value?.name + '\n')
  const getProvidedItems = data?.provided_items.map((value) => '- ' + value?.name + '\n')
  const getWorkerTools = data?.worker_tools.map((value) => '- ' + value?.name + '\n')
  return [
    ...(localStorage.getItem('mode') === 'client' ? [{ label: '作業名', data: data?.job_name }] : []),
    { label: '作業日時', data: data?.date + ' ' + data?.start_time + '~' + data?.end_time },
    { label: '休憩時間', data: data?.break_time },
    { label: '作業場所住所', data: formatAddress(data) },
    { label: '作業内容', data: data?.description },
    { label: '作業費', data: data?.salary_format },
    { label: '手当', data: data?.allowance },
    { label: '交通費', data: data?.toll_fee },
    { label: '高速代', data: data?.highway_fee },
    { label: '駐車場代', data: data?.parking_fee },
    { label: '宿泊費', data: data?.overnight_stay_fee },
    { label: 'その他経費', data: data?.other_fee },
    {
      label: '事前支給品',
      data:
        getProvidedDocuments?.join('') +
        (data?.provided_to_worker_other ? '- ' + data?.provided_to_worker_other + ' ' : ''),
    },
    {
      label: '事前送付品',
      data:
        getProvidedItems?.join('') +
        (data?.items_provided_description ? '- ' + data?.items_provided_description + ' ' : ''),
    },
    {
      label: 'ワーカー持参物',
      data: getWorkerTools?.join('') + (data?.worker_tool_notes ? '- ' + data?.worker_tool_notes + ' ' : ''),
    },
    { label: '服装', data: data?.attire },
    { label: 'エスカレーション先', data: data?.escalation_contact },
    { label: 'お客様現地ご担当者様連絡先', data: data?.onsite_client_contact },
    { label: '名乗り', data: data?.onsite_name },
    { label: '当日同行者', data: data?.fellow },
    { label: 'その他連絡事項', data: data?.remarks },
    {
      label: '必要な連絡と、担当者以外に\n 追加で必要な宛先',
      data:
        (data?.one_day_before_recipients ? '前日連絡 (' + data?.one_day_before_recipients + ')\n' : '') +
        (data?.departure_recipients ? '出発連絡 (' + data?.departure_recipients + ')\n' : '') +
        (data?.arrival_recipients ? '現着連絡 (' + data?.arrival_recipients + ')\n' : '') +
        (data?.clock_in_recipients ? '入館連絡 (' + data?.clock_in_recipients + ')\n' : '') +
        (data?.clock_out_recipients ? '退館連絡 (' + data?.clock_out_recipients + ')\n' : ''),
    },
    { label: '登録日', data: data?.registered_date },
  ]
}

export function formatWorkerJobDetails(data) {
  let formattedJobDetails = formatJobDetails(data)
  const remarks = formattedJobDetails.splice(19, 1)[0] //remove remarks from original index
  formattedJobDetails.splice(15, 0, remarks) //move remarks after dress code index
  formattedJobDetails.splice(-1, 1) //remove registered date in data
  return [...formattedJobDetails]
}

export function formatJobOutline(data) {
  return {
    data: {
      job_name: data?.name ?? '',
      job_name_with_ID: data?.job_name ?? '',
      project_name: data?.project_name ?? '',
      job_group_name: data?.job_group_alias ?? '',
      client_name: data?.client?.name ?? '',
      job_date_time: data?.date + ' ' + data?.start_time + '~' + data?.end_time,
      client_contact_name: data?.client_user?.user?.name ?? '',
      client_contact_email: data?.pic_email ?? '',
      client_contact_phone_number: data?.pic_number ?? '',
    },
  }
}

export function formatDropdownInvoiceMonths(data) {
  return data.map((item) => {
    return {
      id: dayjs(item.value).format('YYYYMM'),
      name: item.text,
    }
  })
}

export function filterProjectStatus(rawStatus) {
  const { open, close } = status.project
  return rawStatus.filter(({ id }) => [open, close].includes(id))
}

export function getJobGoogleCalendarDate(date, start_time, end_time) {
  const startDate = dayjs(date + ' ' + start_time).format(googleCalendarDateFormat)
  const endDate = dayjs(date + ' ' + end_time).format(googleCalendarDateFormat)
  return startDate + '/' + endDate
}

export function fullNameSpaceValidation(value) {
  const converter = new AFHConvert()
  if (eaw.eastAsianWidth(value) === 'W') {
    value = converter.toHalfWidth(value)
  }

  return value.trim().split(' ').length < 2 || /\s{2,}/g.test(value.trim())
}

export function emailAddressValidation(value, hasError = false) {
  const rulesMsg = getMessages('ja')
  const isJobGroup = location.pathname.includes('job-group')

  const emailValidationRegex =
    /^([A-z0-9._%+-]|[一-龠]|[ぁ-ゔ]|[ァ-ヴー]|[々〆〤ヶ])+@([A-Za-z0-9-]|[一-龠]|[ぁ-ゔ]|[ァ-ヴー]|[々〆〤ヶ])+(\.([A-z]|[一-龠]|[ぁ-ゔ]|[ァ-ヴー]|[々〆〤ヶ]){2,})+$/g

  if (!emailValidationRegex.test(value)) {
    // [H] -> half width or [Na] -> Narrow (English Characters)
    if (!['H', 'Na'].includes(eaw.eastAsianWidth(value))) {
      return rulesMsg.half_width
    }

    return isJobGroup ? rulesMsg.jobGroup_email_pattern : rulesMsg.email_pattern
  } else if (hasError) {
    return rulesMsg.email_exists
  }

  return
}

export function linkGenerator({ path, details, methodHandler, name, status, startDate, endDate }) {
  return (
    <Link
      to={path}
      sx={{ textDecoration: 'underline' }}
      onClick={() => {
        methodHandler(name, status, startDate, endDate)
      }}
    >
      {details}
    </Link>
  )
}

export function filterMenuItems(mode, menuItems, user) {
  let links = []
  switch (mode) {
    case 'wkr': {
      links = menuItems.some((obj) => 'type' in obj && obj.type === user?.member_type)
        ? menuItems.filter(({ name }) => ['作業検索', 'ワーカー登録'].includes(name))
        : menuItems.filter(({ name }) => !['ワーカー登録'].includes(name))
      break
    }
    default: {
      links = menuItems
      break
    }
  }

  return links
}

// get all the months in between two dates
export function getMonths(startDate, endDate) {
  const start = startDate.split('/')
  const end = endDate.split('/')
  const startYear = parseInt(start[0])
  const endYear = parseInt(end[0])
  const dates = []

  for (let i = startYear; i <= endYear; i++) {
    let endMonth = i != endYear ? 11 : parseInt(end[1]) - 1
    let startMon = i === startYear ? parseInt(start[1]) - 1 : 0
    for (let j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
      let month = j + 1
      let displayMonth = month < 10 ? '0' + month : month
      let date = [i, displayMonth].join('/')
      dates.push({ value: date, text: date })
    }
  }
  return dates.reverse()
}

export function formatNameID(name, id) {
  if (!name && typeof name === 'undefined') return ''
  const padId = String(id).padStart(3, '0')
  return `${name} (ID: ${padId})`
}

export function formatNumbersOnly(string) {
  if (!string && typeof string === 'undefined') return ''
  return string ? string?.replace(/\D+/g, '') : ''
}

export function formatWorkerProvidedItems(data) {
  return data?.map((item) => item?.name)
}

export function formatWorkerDocuments(typeKey, data, selected) {
  let documentType = typeKey ? data.find(({ type }) => type === typeKey) : {}
  let filteredOptions = documentType?.options.filter((opt) => selected.includes(opt.name))
  return filteredOptions.map((opt) => opt.name)
}

export function formatTimeString(time) {
  if (!time) return ''
  let separetedTime = time.split(':')
  return separetedTime.map((t) => String(t).padStart(2, '0')).join(':')
}

export function formatAmount(amount) {
  if (!amount) return ''
  return '¥ ' + parseInt(String(amount).replace(/[^0-9]/g, '')).toLocaleString()
}

export function formatOfferDropdownData(data) {
  return data.map((item) => {
    return {
      ...item,
      id: parseInt(item.id),
      name: formatNameID(item.name, item.id),
      original_name: item.name,
    }
  })
}

//format default multiselect value
export function formatStringToArray(value) {
  if (!value) return []
  const stringValue = value?.split(',')
  return stringValue.map((val) => parseInt(val))
}

export function maskedData(value) {
  return `${value}`.slice(`${value}`.length).padStart(`${value}`.length, '*')
}

export function formatMaskedNameId(name, id) {
  if (!name && typeof name === 'undefined') return ''
  const padId = String(id).padStart(3, '0')
  return `${maskedData(name)} (ID: ${padId})`
}

export function verifiedAccountToString(value) {
  return value === 2 ? '否認' : value === 1 ? '認証済み' : '未認証'
}

export function verifiedAccountToInt(value) {
  return value === '否認' ? 2 : value === '認証済み' ? 1 : 0
}

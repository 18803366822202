import React, { useState, useEffect } from 'react'
import { Grid, CircularProgress } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useSelector, useDispatch } from 'react-redux'
import { confirmCreateUpdateWorker } from 'services/workers'
import { setConfirming, setFormErrors } from 'store/workers/actionCreators'
import { hideNotification } from 'store/notification/actionCreators'
import { useHistory } from 'react-router-dom'
import SuccessIcon from 'assets/svg/success.svg'
import CheckBoxBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
//utils
import { groupBy, verifiedAccountToString } from 'utils/helper'
import CheckIcon from '@mui/icons-material/Check'
//componets
import { Label, LargeButton, Icon, Link, ContentDisplay, CategoryDisplay, Heading, SubHeading, P } from 'components'

import PropTypes from 'prop-types'

const StyledWorkerConfirmForm = styled(Grid, {
  shouldForwardProp: (prop) => prop,
})(() => ({
  '& .WorkExperience-Text': {
    overflowWrap: 'break-word',
    whiteSpace: 'pre-wrap',
    '& pre': {
      whiteSpace: 'pre-wrap',
    },
  },
}))

const WorkerConfirmForm = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const formData = useSelector((state) => state.workers.formData)
  const loader = useSelector((state) => state.loader)
  const isConfirming = useSelector((state) => state.workers.isConfirming)
  const isUpdate = useSelector((state) => state.workers.isUpdate)
  const [confirmed, setConfirmed] = useState(false)

  const certificates = formData.current?.selectedSkills.filter((skill) => skill.skill_category_type === 'Qualification')
  const canDos = formData.current?.selectedSkills.filter((skill) => skill.skill_category_type === 'Skills')
  const certificatesByCategory =
    certificates && certificates.length > 0 ? groupBy(certificates, 'skill_category_name') : []
  const canDosByCategory = canDos && canDos.length > 0 ? groupBy(canDos, 'skill_category_name') : []
  const handleConfirm = async () => {
    let newFile = ''
    if (formData.current?.id_card_fileUrl) {
      newFile = await fetch(formData.current?.id_card_fileUrl)
        .then((res) => res.blob())
        .then((myBlob) => {
          return new File([myBlob], formData.current?.id_card_filename, {
            type: myBlob.type,
          })
        })
    }
    let newData = {
      ...formData.current,
      id_card_url: newFile,
    }

    await dispatch(confirmCreateUpdateWorker(newData, isUpdate ? formData.original.id : null))
      .then(() => {
        setConfirmed(true)
        props.isConfirm(true)
      })
      .catch((e) => {
        const { code, error } = e.response.data
        const allFields = formData.current ? Object.keys(formData.current) : []
        const allErrors = error ? Object.keys(error) : []
        const hasFieldError = allFields.some((item) => allErrors.includes(item))
        if (code === 422 && hasFieldError) {
          dispatch(hideNotification())
          dispatch(setFormErrors(error))
        }
        history.goBack()
      })
  }

  useEffect(() => {
    if (!confirmed && (!isConfirming || !formData.current)) {
      history.push('/worker/create')
    }
    return () => {
      dispatch(setConfirming(false))
    }
  }, [dispatch])

  return (
    <StyledWorkerConfirmForm container className={confirmed ? 'Confirmation' : ''}>
      {confirmed && (
        <Grid item container justifyContent="center" alignItems="center" textAlign="center">
          <Grid item xs={12} sm={10} pt={4}>
            <Icon source={SuccessIcon} />
          </Grid>
          <Grid item xs={12} sm={10} pt={3} pb={3} gap={4}>
            <SubHeading color="secondary">
              {' '}
              {!isUpdate ? 'ワーカー登録が完了しました。' : 'ワーカー編集が完了しました。'}
            </SubHeading>
          </Grid>
          <Grid item xs={12} sm={10} pb={2}>
            <Link to={'/worker/create'}>
              <LargeButton id="CreatNewWorkerBtn" color="primary" mode="light">
                別のワーカーを登録
              </LargeButton>
            </Link>
          </Grid>
          <Grid item xs={12} sm={10} pb={6}>
            <Link id="ConfirmedBackBtn" to={'/worker_list'}>
              <u>ワーカー一覧</u>
            </Link>
          </Grid>
        </Grid>
      )}
      {!confirmed && (
        <Grid item container>
          <Grid item xs={12}>
            <Heading>{!isUpdate ? 'ワーカー登録確認' : 'ワーカー編集確認'}</Heading>
          </Grid>
          <Grid item xs={12} pb={3} pt={1}>
            <SubHeading>以下の内容で登録しますか？</SubHeading>
          </Grid>
          <ContentDisplay label="お名前:" data={formData.current?.full_name} />
          <ContentDisplay label="メールアドレス:" data={formData.current?.email_address} />
          <ContentDisplay label="表示名:" data={formData.current?.display_name} />
          <ContentDisplay label="性別:" data={formData.current?.gender} />
          <ContentDisplay label="生年月日:" data={formData.current?.birthday} />
          <ContentDisplay label="郵便番号:" data={formData.current?.postal_code} />
          <ContentDisplay label="都道府県:" data={formData.current?.prefecture} />
          <ContentDisplay label="市町村区:" data={formData.current?.city} />
          <ContentDisplay label="町名番地:" data={formData.current?.address_line_1} />
          <ContentDisplay label="以降の住所:" data={formData.current?.address_line_2} />
          <ContentDisplay label="電話番号:" data={formData.current?.phone_number} />
          <ContentDisplay label="個人証明(任意):" data={formData.current?.id_card_filename} />
          <ContentDisplay
            label="適格請求書発行事業者（任意):"
            data={formData.current?.tekikaku_flag === 1 ? <CheckIcon /> : <CheckBoxBlankIcon />}
          />
          <ContentDisplay
            label="新着作業メールを受け取る:"
            data={formData.current?.receive_new_jobs_email === 1 ? <CheckIcon /> : <CheckBoxBlankIcon />}
          />
          <ContentDisplay label="親方（任意）:" data={formData.current?.company_name} />
          <ContentDisplay label="銀行口座(任意):" data={formData.current?.bank_account} />
          <ContentDisplay label="請求元（任意）:" data={formData.current?.biller} />
          <ContentDisplay label="本人確認:" data={verifiedAccountToString(formData.current?.verified_account)} />
          <ContentDisplay label="アカウント状態:" data={formData.current?.account_status === 1 ? '有効' : '無効'} />
          {certificates && certificates.length !== 0 && (
            <Grid item pt={10} xs={12} textAlign="center">
              <SubHeading bold="true">所有資格</SubHeading>
            </Grid>
          )}
          {Object.entries(certificatesByCategory).map((items, i) => {
            return (
              <Grid item xs={12} key={i} pt={4} textAlign="center">
                <Label color="main" pb={2}>
                  {items[0]}:
                </Label>
                <CategoryDisplay items={items[1]} />
              </Grid>
            )
          })}
          {canDos && canDos.length !== 0 && (
            <Grid item pt={10} xs={12} textAlign="center">
              <SubHeading bold="true">対応作業</SubHeading>
            </Grid>
          )}
          {Object.entries(canDosByCategory).map((items, i) => {
            return (
              <Grid item xs={12} key={i} pt={4} textAlign="center">
                <CategoryDisplay items={items[1]} />
              </Grid>
            )
          })}
          <Grid item xs={12} pt={4}>
            <Label bold="true">業務経験:</Label>
            <pre>
              <P className="WorkExperience-Text">{formData.current?.work_experience}</P>
            </pre>
          </Grid>
          <Grid item container xs={12} pt={6} justifyContent={{ xs: 'center', sm: 'end' }} gap={2}>
            <LargeButton id="ConfirmBackBtn" color="gray" mode="btn1" onClick={history.goBack}>
              戻る
            </LargeButton>
            <LargeButton id="WorkerConfirmBtn" onClick={handleConfirm}>
              {loader && <CircularProgress size="1.5rem" color="white" />}
              {!loader && '確定'}
            </LargeButton>
          </Grid>
        </Grid>
      )}
    </StyledWorkerConfirmForm>
  )
}

WorkerConfirmForm.displayName = 'Job Group Confirmation Form'

WorkerConfirmForm.propTypes = {
  isConfirm: PropTypes.func,
}

export default WorkerConfirmForm

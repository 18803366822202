import * as types from './actionTypes'

export function actionSearchWorkers(workerList) {
  return {
    type: types.SEARCH_WORKERS,
    payload: workerList,
  }
}

export function actionSetSearchCriteria(
  name,
  screen_name,
  id,
  email_address,
  phone_number,
  member_type,
  page,
  limit,
  sort,
  sortBy
) {
  return {
    type: types.SET_SEARCH_CRITERIA,
    payload: { name, screen_name, id, email_address, phone_number, member_type, page, limit, sort, sortBy },
  }
}

// Worker Details
export function actionGetWorkerDetailsSuccess(data) {
  return {
    type: types.GET_WORKER_DETAILS_SUCCESS,
    payload: data,
  }
}

export function actionGetWorkerDetailsFailed(error) {
  return {
    type: types.GET_WORKER_DETAILS_FAILED,
    payload: { error },
  }
}

export function actionRegisterWorkerEmailSuccess(data) {
  return {
    type: types.REGISTER_WORKER_EMAIL_SUCCESS,
    payload: data,
  }
}

export function actionRegisterWorkerEmailFailed(error) {
  return {
    type: types.REGISTER_WORKER_EMAIL_SUCCESS,
    payload: { error },
  }
}

export function actionGetWorkerSkillsSuccess(data) {
  return {
    type: types.GET_WORKER_SKILLS_SUCCESS,
    payload: data,
  }
}

export function actionGetWorkerSkillsFailed(error) {
  return {
    type: types.GET_WORKER_SKILLS_FAILED,
    payload: { error },
  }
}

export function setWorkersFormData(formData) {
  return {
    type: types.SET_WORKERS_FORM_DATA,
    payload: formData,
  }
}

export function setConfirming(confirming) {
  return {
    type: types.SET_CONFIRMING_DATA,
    payload: confirming,
  }
}

export function setFormMode(isUpdate) {
  return {
    type: types.SET_FORM_MODE,
    payload: isUpdate,
  }
}

export function setFormErrors(errors) {
  return {
    type: types.SET_FORM_ERRORS,
    payload: errors,
  }
}

export function resetCurrentForm() {
  return {
    type: types.RESET_CURRENT_FORM,
  }
}

export function actionSaveWorkerInformation(data) {
  return {
    type: types.SAVE_WORKER_INFORMATION_SUCCESS,
    payload: data,
  }
}

export function actionSaveWorkerInformationFailed(error) {
  return {
    type: types.SAVE_WORKER_INFORMATION_FAILED,
    payload: { error },
  }
}

export function getWorkerJobListRequest() {
  return {
    type: types.GET_WORKER_JOB_LIST_REQUEST,
  }
}

export function getWorkerJobListSuccess(data) {
  return {
    type: types.GET_WORKER_JOB_LIST_SUCCESS,
    payload: data,
  }
}

export function getWorkerJobListFailure(error) {
  return {
    type: types.GET_WORKER_JOB_LIST_FAILURE,
    payload: error,
  }
}

export function fetchingDataFail(error) {
  return {
    type: types.FETCHING_DATA_FAILED,
    payload: error,
  }
}

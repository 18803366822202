import React from 'react'
import { Grid } from '@mui/material'
import PropTypes from 'prop-types'

import {
  LargeButton,
  Link,
  ContentDisplay,
  Label,
  CategoryDisplay,
  BackArrow,
  Heading,
  SubHeading,
  P,
} from 'components'
import { styled } from '@mui/material/styles'
import { groupBy } from 'utils/helper'
import CheckIcon from '@mui/icons-material/Check'
import CheckBoxBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
//utils
import { verifiedAccountToString } from 'utils/helper'

const StyledWorker = styled(Grid, {
  shouldForwardProp: (prop) => prop,
})(() => ({
  '& .WorkExperience-Text': {
    overflowWrap: 'break-word',
    whiteSpace: 'pre-wrap',
    '& pre': {
      whiteSpace: 'pre-wrap',
    },
  },
}))

const WorkerInfo = (props) => {
  const { worker } = props

  const skills = worker?.skills ?? []
  const certificates = skills.filter((skill) => skill.skill_category_type === 'Qualification')
  const canDos = skills.filter((skill) => skill.skill_category_type === 'Skills')

  const certificatesByCategory = groupBy(certificates, 'skill_category_name')
  const canDosByCategory = groupBy(canDos, 'skill_category_name')
  const postalCode = worker?.postal_code ?? ''
  const prefecture = worker?.prefecture ?? ''
  const city = worker?.city ?? ''
  const addressLine1 = worker?.address_line_1 ?? ''
  const addressLine2 = worker?.address_line_2 ?? ''

  const address = `${postalCode} ${prefecture} ${city} ${addressLine1} ${addressLine2}`

  return (
    <StyledWorker container pb={4}>
      <BackArrow label="ワーカー一覧に戻る" path="/worker_list" />
      <Grid item container>
        <Grid item xs={12} pt={2}>
          <Heading>ワーカー詳細</Heading>
        </Grid>
        <Grid item xs={12} py={2}>
          <SubHeading bold="true">基本情報</SubHeading>
        </Grid>
      </Grid>
      {worker && (
        <Grid item container>
          <ContentDisplay label="お名前:" data={worker.name} />
          <ContentDisplay label="メールアドレス:" data={worker.email_address} />
          <ContentDisplay label="表示名:" data={worker.display_name} />
          <ContentDisplay label="性別:" data={worker.gender} />
          <ContentDisplay label="生年月日:" data={worker.birthday} />
          <ContentDisplay label="住所:" data={address} />
          <ContentDisplay label="電話番号:" data={worker.phone_number} />
          <ContentDisplay
            label="個人証明(任意):"
            data={worker?.id_card_url?.replace(/^.*[\\/]/, '') ?? ''}
            filePath={worker?.id_card_url}
            isDownload={worker.id_card_url != null}
          />
          <ContentDisplay
            label="適格請求書発行事業者:"
            data={worker.tekikaku_flag === 1 ? <CheckIcon /> : <CheckBoxBlankIcon />}
          />
          <ContentDisplay
            label="新着作業メールを受け取る:"
            data={worker.receive_new_jobs_email === 1 ? <CheckIcon /> : <CheckBoxBlankIcon />}
          />
          <ContentDisplay label="親方:" data={worker.company_name} />
          <ContentDisplay label="登録日:" data={worker.registered_date} />
          <ContentDisplay label="銀行口座:" data={worker.bank_account} />
          <ContentDisplay label="請求元:" data={worker.biller} />
          <ContentDisplay label="本人確認:" data={verifiedAccountToString(worker.verified_account)} />
          <ContentDisplay label="アカウント状態:" data={worker.account_status === 1 ? '有効' : '無効'} />
          <ContentDisplay label="最終ログイン:" data={worker.last_login} />
          {certificates.length !== 0 && (
            <Grid item pt={10} xs={12} textAlign="center">
              <SubHeading bold="true">所有資格</SubHeading>
            </Grid>
          )}
          {Object.entries(certificatesByCategory).map((items, i) => {
            return (
              <Grid item xs={12} key={i} pt={4} textAlign="center">
                <Label color="main" pb={2}>
                  {items[0]}:
                </Label>
                <CategoryDisplay items={items[1]} />
              </Grid>
            )
          })}
          {canDos.length !== 0 && (
            <Grid item pt={10} xs={12} textAlign="center">
              <SubHeading bold="true">対応作業</SubHeading>
            </Grid>
          )}
          {Object.entries(canDosByCategory).map((items, i) => {
            return (
              <Grid item xs={12} key={i} pt={4} textAlign="center">
                <CategoryDisplay items={items[1]} />
              </Grid>
            )
          })}
          <Grid item pt={10}>
            <Label bold="true">業務経験</Label>
          </Grid>
          <Grid item pt={1} xs={12} sm={12} md={12} lg={12}>
            <pre>
              <P className="WorkExperience-Text">{worker?.work_experience}</P>
            </pre>
          </Grid>
          <Grid item container pt={8} pb={2} justifyContent={{ xs: 'center', sm: 'end' }}>
            <Grid item xs={12} sm={4} md={3} lg={2} textAlign="end">
              <Link to={`update/${worker.id}`}>
                <LargeButton>編集</LargeButton>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      )}
    </StyledWorker>
  )
}

WorkerInfo.displayName = 'Worker Info'

WorkerInfo.propTypes = {
  worker: PropTypes.any,
}

export default WorkerInfo

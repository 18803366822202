import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Grid, FormHelperText, CircularProgress } from '@mui/material'
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline'
import { styled } from '@mui/material/styles'
import { useForm } from 'react-hook-form'
import { setFormMode, setConfirming, setWorkersFormData, setFormErrors } from 'store/workers/actionCreators'
import { hideNotification } from 'store/notification/actionCreators'
import { getWorker, confirmWorkerData } from 'services/workers'
import { getAddress, downloadFile } from 'services/common'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as dayjs from 'dayjs'
import * as eaw from 'eastasianwidth'
import * as AFHConvert from 'ascii-fullwidth-halfwidth-convert'
//utils
import { dateFormat, minBirthDate } from 'utils/common'
import { verifiedAccountToString, verifiedAccountToInt } from 'utils/helper'
//componets
import {
  Label,
  InputLabel,
  InputDatePicker,
  InputTextArea,
  InputMultiCheckbox,
  InputRadioGroup,
  LargeButton,
  Input,
  Checkbox,
  Heading,
  SubHeading,
  P,
  Caption,
} from 'components'

const StyledWorkerCreateUpdateForm = styled(Grid, {
  shouldForwardProp: (prop) => prop,
})(({ theme }) => ({
  '& .ProjectList-form': {
    width: '100%',
  },
  '& .SkillsWrapper_6 .CheckboxWrapper': {
    display: 'grid',
    gridTemplateRows: 'repeat(17, auto)',
    gridAutoFlow: 'column',
    gridAutoColumns: 'auto',
    gridTemplateColumns: '50%',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  '& input.Mui-disabled': {
    backgroundColor: `${theme.palette.lightGray.dark}`,
    borderRadius: 5,
  },
  '& .DisplayNameWrapper .MuiBox-root': {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 0,
    },
  },
  '& .MuiButton-root.UploadBtn': {
    borderRadius: 5,
    '& .MuiFormControl-root': {
      display: 'none',
    },
  },
  '& input#InputUploadFile-ID': {
    display: 'none',
  },
  '& .WorkExperience-Wrapper': {
    '& .MuiBox-root:first-of-type': {
      padding: 0,
    },
    '& p.MuiFormHelperText-root.WorkExperience-Note': {
      color: `${theme.palette.text.primary} !important`,
      fontSize: 12,
      textAlign: 'end',
    },
  },
  '& .BankAccount-Wrapper': {
    '& .InputLabel': {
      padding: 0,
    },
    '& .InputLabel-subLabel': {
      paddingBottom: `${theme.standards.padding.label.inputLabel}`,
    },
  },
  '& .download': {
    cursor: 'pointer',
  },
}))

const WorkerCreateUpdateForm = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  //useform hook
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    trigger,
    setError,
    clearErrors,
  } = useForm()

  //form data
  const currentformData = useSelector((state) => state.workers.formData)
  const formErrors = useSelector((state) => state.workers.formErrors)
  const workerSkills = useSelector((state) => state.common.workerSkillList)
  const loader = useSelector((state) => state.loader)
  let formData =
    currentformData.current && Object.keys(currentformData.current).length > 0
      ? currentformData.current
      : currentformData.original

  //state
  const [isUpdate, setIsUpdate] = useState(false)
  const [cardUrlFilename, setCardUrlFilename] = useState('ファイルは選択されていません')
  const [selectedSkills, setSelectedSkills] = useState([])
  const [selectedSkillsID, setSelectedSkillsID] = useState([])
  const [isCurrentCard, setIsCurrentCard] = useState(true)
  const [requestAddress, setRequestAddress] = useState(false)
  const [selectedGender, setSelectedGender] = useState('男性')
  const [prefecture, setPrefecture] = useState('')
  const [city, setCity] = useState('')
  const [addressLine1, setAddressLine1] = useState('')
  const [isCheckedTekikaku, setIsCheckedTekikaku] = useState(0)
  const [selectedVerifiedAccount, setSelectedVerifiedAccount] = useState('未認証')
  const [isCheckedAccStatus, setIsCheckedAccStatus] = useState(0)
  const [emailChecked, setEmailIsChecked] = useState(0)

  //dispatch hooks
  useEffect(() => {
    let workerId = props?.match?.params?.id
    if (props?.location?.pathname.includes('update')) {
      setIsUpdate(true)
      dispatch(setFormMode(true))
    } else {
      setIsUpdate(false)
      dispatch(setFormMode(false))
    }
    if (workerId) {
      dispatch(getWorker(workerId))
    }
  }, [dispatch])

  //formErrors hooks
  useEffect(() => {
    Object.keys(formErrors).map((key) => {
      setError(key, { type: 'custom', message: formErrors[key][0] }, { shouldFocus: true })
    })
  }, [formErrors])

  //formData hooks
  useEffect(() => {
    setCardUrlFilename(formData?.id_card_filename ? formData?.id_card_filename : 'ファイルは選択されていません')
    setSelectedSkillsID(formData?.skills.length > 0 ? formData?.skills.map((id) => parseInt(id)) : [])
    setSelectedGender(formData?.gender ? formData?.gender : '男性')
    setPrefecture(formData?.prefecture ?? '')
    setCity(formData?.city ?? '')
    setAddressLine1(formData?.address_line_1 ?? '')
    setValue(
      'skills',
      formData?.skills.map((skill) => skill.toString())
    )
    setIsCheckedTekikaku(formData?.tekikaku_flag ?? 0)
    setSelectedVerifiedAccount(verifiedAccountToString(formData?.verified_account))
    setEmailIsChecked(formData?.receive_new_jobs_email ?? 0)
    setIsCheckedAccStatus(!formData ? 1 : formData?.account_status)
  }, [formData])

  // handle Submit
  const handleSubmitWorkerForm = (data) => {
    if (eaw.eastAsianWidth(data?.full_name) !== 'F' || eaw.eastAsianWidth(data?.full_name) !== 'H') {
      const converter = new AFHConvert()
      if (eaw.eastAsianWidth(data?.full_name) === 'W') {
        data.full_name = converter.toFullWidth(data?.full_name)
      } else {
        data.full_name = converter.toHalfWidth(data?.full_name)
      }
    }

    data.tekikaku_flag = isCheckedTekikaku
    data.account_status = isCheckedAccStatus
    data.verified_account = verifiedAccountToInt(selectedVerifiedAccount)
    data.receive_new_jobs_email = emailChecked

    workerSkills.map((type) =>
      type?.skills.map((skill) => {
        let skills = {
          skill_category_name: type.name,
          skill_category_type: type.type,
          skill_id: skill.id,
          skill_name: skill.name,
        }
        selectedSkillsID.length > 0 && selectedSkillsID.includes(parseInt(skill.id)) && selectedSkills.push(skills)
        setSelectedSkills(selectedSkills)
      })
    )

    let cardUrl = formData?.id_card_fileUrl || data?.id_card_url
    let cardFile = ''
    if (data.id_card_url[0] && data.id_card_url.length > 0) {
      cardFile = data.id_card_url[0]
      cardUrl = URL.createObjectURL(data.id_card_url[0])
    }

    let newData = {
      ...data,
      id_card_url: cardFile,
      id_card_fileUrl: cardUrl,
      id_card_filename: data.id_card_url.length > 0 ? data.id_card_url[0].name : formData?.id_card_filename,
      selectedSkills: selectedSkills,
      skills: selectedSkillsID,
    }

    dispatch(confirmWorkerData(newData, isUpdate ? currentformData?.original?.id : null))
      .then(() => {
        dispatch(setWorkersFormData({ current: newData }))
        dispatch(setConfirming(true))
        dispatch(setFormMode(isUpdate))
        dispatch(setFormErrors(null))
        history.push('/worker/confirmation')
      })
      .catch((e) => {
        const { code, error } = e.response.data
        const allFields = Object.keys(newData)
        const allErrors = error ? Object.keys(error) : []
        const hasFieldError = allFields.some((item) => allErrors.includes(item))
        if (code === 422 && hasFieldError) {
          dispatch(hideNotification())
          dispatch(setFormErrors(error))
        }
      })
  }

  const handleSelectSkills = (e) => {
    if (!e.target.checked) {
      const index = selectedSkillsID.indexOf(parseInt(e.target.value))
      const selectedID = selectedSkillsID
      if (index > -1) {
        selectedID.splice(index, 1)
        setSelectedSkillsID(selectedID)
      }
    }

    if (e.target.checked && !selectedSkillsID.includes(e.target.value)) {
      setSelectedSkillsID((prevState) => [...prevState, parseInt(e.target.value)])
    }
  }

  //onClick upload ID Card
  const handleUploadCard = (event) => {
    if (event?.target?.files !== null && event.target?.files?.length > 0) {
      setCardUrlFilename(event.target.files[0].name)
    } else {
      setCardUrlFilename('ファイルは選択されていません')
    }
    setIsCurrentCard(false)
  }
  const onDownloadCard = () => {
    dispatch(
      downloadFile({ file_path: currentformData?.original?.id_card_url }, currentformData?.original?.id_card_filename)
    )
  }

  // onClick get address by postal code
  const handleGetAddress = async () => {
    const validPostal = await trigger('postal_code')
    let postal_code = getValues('postal_code')
    if (validPostal) {
      setRequestAddress(true)
      await getAddress(postal_code).then((response) => {
        setRequestAddress(false)
        if (response.data.results == null) {
          setError('postal_code', { type: 'custom', message: '該当する郵便番号が見つかりませんでした。' })
        } else {
          clearErrors('postal_code')
          clearErrors('prefecture')
          clearErrors('city')
          setPrefecture(response.data.results[0]?.address1)
          setCity(response.data.results[0]?.address2)
          setAddressLine1(response.data.results[0]?.address3)
        }
      })
    }
  }
  //onChange postal input value
  const handleChangePostal = () => {
    if (errors.postal_code) {
      trigger('postal_code')
    }
    clearErrors('prefecture')
    clearErrors('city')
    setPrefecture('')
    setCity('')
  }
  //onChange inputs reset errors
  const handleInputChange = (name) => {
    if (errors[name]) {
      trigger(name)
    }
  }
  //onChange gender options
  const onChangeGender = (e) => {
    setSelectedGender(e.target.value)
  }
  //onChange verified account options
  const onChangeVerifiedAccount = (e) => {
    setSelectedVerifiedAccount(e.target.value)
  }
  //form validation
  const validationRules = {
    display_name: {
      required: {
        value: String,
        message: '表示名を入力してください。',
      },
      validate: (value) => {
        if (value.trim().length <= 0) {
          return '表示名を入力してください。'
        } else if (value.trim().length > 30) {
          return '30文字以内で入力してください。'
        } else {
          return
        }
      },
    },
    full_name: {
      required: {
        value: String,
        message: 'お名前を入力してください。',
      },
      validate: (value) => {
        if (value.trim().length <= 0) {
          return 'お名前を入力してください。'
        } else if (value.trim().length > 30) {
          return '30文字以内で入力してください。'
        } else if (fullNameSpaceValidation(value)) {
          return '姓と名の間にスペースを入力してください。'
        } else {
          return
        }
      },
    },
    email_address: {
      required: {
        value: String,
        message: 'メールアドレスを入力してください。',
      },
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: 'メールアドレスの書式が正しくありません。',
      },
    },
    birthday: {
      required: {
        value: String,
        message: '生年月日を入力してください。',
      },
      validate: (value) => {
        const isValid = dayjs(value, dateFormat, true).isValid()
        const minAge = 18
        const currentDate = dayjs()
        const birthDate = dayjs(value)

        if (!isValid) {
          return 'YYYY/MM/DDの形式で入力してください。'
        } else if (minAge > currentDate.diff(birthDate, 'year')) {
          return '18歳以上の方のみご登録可能です。'
        } else {
          return true
        }
      },
    },
    postal_code: {
      required: {
        value: String,
        message: '郵便番号を入力してください。',
      },
      validate: (value) => {
        if (value.length > 7) {
          return '7桁の半角数字を入力してください。'
        } else if (!/^[0-9]*$/.test(value)) {
          return '7桁の半角数字を入力してください。'
        } else {
          return true
        }
      },
    },
    prefecture: {
      required: {
        value: String,
        message: '都道府県は必須項目です。',
      },
    },
    city: {
      required: {
        value: String,
        message: '市町村区は必須項目です。',
      },
    },
    address_line_1: {
      required: {
        value: String,
        message: '町名番地を入力してください。',
      },
      validate: (value) => {
        return value.length <= 255 || '255文字以内で入力してください。'
      },
    },
    address_line_2: {
      validate: (value) => {
        return value.length <= 255 || '255文字以内で入力してください。'
      },
    },
    phone_number: {
      required: {
        value: Number,
        message: '電話番号を入力してください。',
      },
      validate: (value) => {
        if (value.length < 10) {
          return '10桁か11桁の半角数字を入力してください。'
        } else if (value.length > 11) {
          return '10桁か11桁の半角数字を入力してください。'
        } else if (!/^[0-9]*$/.test(value)) {
          return '10桁か11桁の半角数字を入力してください。'
        } else {
          return true
        }
      },
      pattern: {
        value: /^(0|[0-9]\d*)(\.\d+)?$/,
        message: '10桁か11桁の半角数字を入力してください。',
      },
    },
    company_name: {
      validate: (value) => {
        return value.length <= 255 || '255文字以内で入力してください。'
      },
    },
    bank_account: {
      validate: (value) => {
        return value.length <= 500 || '500文字以内で入力してください。'
      },
    },
    biller: {
      validate: (value) => {
        return value.length <= 500 || '500文字以内で入力してください。'
      },
    },
    work_experience: {
      required: {
        value: String,
        message: '業務経験を入力してください。',
      },
      validate: (value) => {
        return value.length <= 1000 || '1000文字以内で入力してください。'
      },
    },
    id_card_url: {
      validate: (value) => {
        if (value !== null && value.length > 0) {
          const filename = value[0].name
          const fileSize = value[0].size / 1024 / 1024 // in MB
          let allowedExtensions = /(\.pdf|\.jpg|\.jpeg|\.JPG|\.JPEG|\.png|\.PNG|\.gif|\.GIF)$/i

          if (process.env.REACT_APP_CLAMAV_TEST === 'true') {
            allowedExtensions = /(\.pdf|\.jpg|\.jpeg|\.JPG|\.JPEG|\.png|\.PNG|\.gif|\.GIF|\.james|\.JAMES)$/i
          }

          if (!allowedExtensions.exec(filename)) {
            return 'JPEG, JPG, PNG, GIF, PDFいずれかのファイルを選択してください。'
          } else if (fileSize > 4) {
            return 'ファイルサイズは4MB以下にしてください。'
          }
        }
        return true
      },
    },
  }

  const fullNameSpaceValidation = (value) => {
    const converter = new AFHConvert()
    if (eaw.eastAsianWidth(value) === 'W') {
      value = converter.toHalfWidth(value)
    }

    return value.trim().split(' ').length < 2 || /\s{2,}/g.test(value.trim())
  }

  let defaultInputProps = {
    required: true,
  }

  const getInputLabelField = (label, name, placeholder, disabled) => {
    let inputValue = formData ? formData[name] : null
    if (name === 'prefecture') {
      inputValue = prefecture
    }
    if (name === 'city') {
      inputValue = city
    }
    if (name === 'address_line_1') {
      inputValue = addressLine1
    }
    let required = defaultInputProps.required
    if (name === 'company_name' || name === 'address_line_2') {
      required = false
    }
    return (
      <InputLabel
        id={name + '-ID'}
        className={name === 'display_name' ? 'NoPad' : ''}
        ref={register(name, validationRules[name])}
        error={errors && errors[name] ? true : false}
        errorMsg={errors ? errors[name]?.message : null}
        placeholder={placeholder}
        name={name}
        label={label}
        isDisabled={disabled || false}
        {...(inputValue && { value: inputValue })}
        {...defaultInputProps}
        required={required || false}
        onChange={name == 'postal_code' ? () => handleChangePostal() : () => handleInputChange(name)}
      />
    )
  }

  const getInputTextAreaField = (label, subLabel, name, placeholder, height, required) => {
    let inputValue = formData ? formData[name] : null
    defaultInputProps['height'] = height
    defaultInputProps['required'] = required
    return (
      <InputTextArea
        id={name + '-ID'}
        ref={register(name, validationRules[name])}
        error={errors && errors[name] ? true : false}
        errorMsg={errors ? errors[name]?.message : null}
        placeholder={placeholder}
        name={name}
        label={label}
        subLabel={subLabel}
        {...(inputValue && { value: inputValue })}
        {...defaultInputProps}
      />
    )
  }

  const getInputMultiCheckbox = (label, name, items, subLabel) => {
    return (
      <InputMultiCheckbox
        ref={register(name + '[]')}
        name={name + '[]'}
        label={label}
        subLabel={subLabel}
        items={items}
        selected={selectedSkillsID}
        onChange={handleSelectSkills}
        labelProps={{
          bold: 'true',
        }}
      />
    )
  }

  const tekikakuOnChange = () => {
    setIsCheckedTekikaku(isCheckedTekikaku === 1 ? 0 : 1)
  }

  const accStatusOnChange = () => {
    setIsCheckedAccStatus(isCheckedAccStatus === 1 ? 0 : 1)
  }

  const recieveEmailChange = () => {
    setEmailIsChecked(emailChecked === 1 ? 0 : 1)
  }

  return (
    <StyledWorkerCreateUpdateForm container>
      <form onSubmit={handleSubmit(handleSubmitWorkerForm)} className="form">
        <Grid container>
          <Grid item xs={12}>
            <Heading>{isUpdate ? 'ワーカー編集' : 'ワーカー登録'}</Heading>
          </Grid>
          <Grid item xs={12} py={2}>
            <SubHeading bold="true">基本情報</SubHeading>
          </Grid>
        </Grid>
        <Grid container columnSpacing={{ xs: 0, md: 4 }}>
          <Grid item xs={6} pb={2}>
            {getInputLabelField('表示名', 'display_name', '例：タロウ', false)}
            <Caption>プロフィールページやワーカー検索結果ページで表示されます</Caption>
          </Grid>
          <Grid item xs={12}>
            {getInputLabelField('お名前', 'full_name', '例: 佐々通 太郎')}
          </Grid>
          <Grid item xs={12} md={6}>
            {getInputLabelField('メールアドレス', 'email_address', '例: info@sasatsu.co.jp')}
          </Grid>
          <Grid item xs={12} md={6}>
            <InputDatePicker
              id={'birthday-ID'}
              ref={register('birthday', validationRules.birthday)}
              error={errors && errors.birthday ? true : false}
              errorMsg={errors ? errors.birthday?.message : null}
              name="birthday"
              label="生年月日"
              placeholder="例: 1980/01/30"
              inputFormat={dateFormat}
              maxDate={dayjs(minBirthDate)}
              defaultCalendarMonth={dayjs(minBirthDate)}
              disableHighlightToday
              required
              color="primary"
              {...(formData && formData['birthday'] && { value: formData['birthday'] })}
              {...defaultInputProps}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <InputRadioGroup
              label="性別"
              name="gender"
              id={'RadioGroup-gender-ID'}
              ref={register('gender')}
              value={selectedGender || '男性'}
              items={['男性', '女性', '無回答']}
              onChange={onChangeGender}
              required
            />
          </Grid>
        </Grid>
        <Grid container spacing={{ xs: 0, md: 4 }}>
          <Grid item xs={12} md={6}>
            {getInputLabelField('郵便番号', 'postal_code', '例: 9820011')}
          </Grid>
          <Grid item container xs={12} md={6} pb={{ xs: 3, md: 0 }} alignItems={'center'}>
            <LargeButton variant="outlined" color="green" mode="light" onClick={handleGetAddress} id="PostalCodeBtn-ID">
              {requestAddress && <CircularProgress size="1.5rem" color="inherit" />}
              {!requestAddress && '郵便番号から住所を検索'}
            </LargeButton>
          </Grid>
        </Grid>
        <Grid container spacing={{ xs: 0, md: 4 }}>
          <Grid item xs={12} md={6}>
            {getInputLabelField('都道府県', 'prefecture', '', true)}
          </Grid>
          <Grid item xs={12} md={6}>
            {getInputLabelField('市町村区', 'city', '', true)}
          </Grid>
        </Grid>
        <Grid container columnSpacing={{ xs: 0, md: 4 }}>
          <Grid item xs={12}>
            {getInputLabelField('町名番地', 'address_line_1', '例: 長町６−１３−２')}
          </Grid>
          <Grid item xs={12}>
            {getInputLabelField('以降の住所', 'address_line_2', '例: フリシャー仙台ビル１０４')}
          </Grid>
          <Grid item xs={12} md={6}>
            {getInputLabelField('電話番号', 'phone_number', '例: 09012345678')}
          </Grid>
          <Grid item xs={12} pb={1}>
            <Label>個人証明(任意)</Label>
          </Grid>
          <Grid item xs={12} md={3}>
            <LargeButton
              id="UploadIdCardBtn-ID"
              className="UploadBtn"
              color="lightGray"
              mode="dark"
              component="label"
              maxwidth="unset"
            >
              ファイル選択
              <Input
                id="InputUploadFile-ID"
                type="file"
                name="id_card_url"
                ref={register('id_card_url', validationRules.id_card_url)}
                hidden
                onChange={handleUploadCard}
              />
            </LargeButton>
          </Grid>
          <Grid item xs={12} md={9} display="inherit" alignItems="center" pl={{ xs: 0, md: 2 }}>
            <P>{cardUrlFilename}</P>
            &nbsp;
            {isUpdate && currentformData?.original?.id_card_url && isCurrentCard && (
              <DownloadForOfflineIcon color="secondary" onClick={onDownloadCard} className="download" />
            )}
          </Grid>
          <Grid item xs={12} pt={1} pb={3}>
            {errors.id_card_url && (
              <FormHelperText id={'FormHelperText-id_card_url-ID'} error>
                {errors.id_card_url.message}
              </FormHelperText>
            )}
            <Caption>
              例: 免許書、保険書、パスポートなど。<br></br>
              個人認証済みというアイコンが表示されオファーされやすくなります。
            </Caption>
          </Grid>
          <Grid item container pb={2} pt={1}>
            <Grid item pb={1}>
              <Label>適格請求書発行事業者（任意)</Label>
            </Grid>
            <Grid item container xs={12} alignItems={'center'}>
              <Grid item>
                <Checkbox
                  id={`${'tekikaku_flag'}-ID`}
                  ref={register('tekikaku_flag')}
                  name={'tekikaku_flag'}
                  onChange={tekikakuOnChange}
                  checked={formData?.tekikaku_flag === 1 || false}
                  label={''}
                  pl={3}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item container pb={2}>
            <Grid item pb={1}>
              <Label>新着作業メールを受け取る (任意)</Label>
            </Grid>
            <Grid item container xs={12} alignItems={'center'}>
              <Grid item>
                <Checkbox
                  id={'receive_new_jobs_email-ID'}
                  ref={register('receive_new_jobs_email')}
                  name={'receive_new_jobs_email'}
                  onChange={recieveEmailChange}
                  checked={formData?.receive_new_jobs_email === 1 || false}
                  label={''}
                  pl={3}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {getInputLabelField('親方 (任意)', 'company_name', '例: ◯◯有限会社 小林')}
          </Grid>
          <Grid item xs={12} className="BankAccount-Wrapper">
            {getInputTextAreaField(
              '銀行口座 (任意)',
              '請求書に記載する振込先',
              'bank_account',
              '例:\n◯◯銀行△△支店\n普通123456\nササツウタロウ',
              126
            )}
          </Grid>
          <Grid item xs={12}>
            {getInputTextAreaField(
              '請求元 (任意）',
              '',
              'biller',
              '例:\n〒987-6543\nXX県XX市XX区YY 9-8-7\nZZZビル555\n\n佐々通 太郎',
              173
            )}
          </Grid>
          <Grid item container direction="row" pb={1}>
            <Grid container>
              <Grid item xs={12}>
                <InputRadioGroup
                  label="本人確認"
                  name="verified_account"
                  id={'RadioGroup-verified_account-ID'}
                  ref={register('verified_account')}
                  value={selectedVerifiedAccount || '未認証'}
                  items={['未認証', '認証済み', '否認']}
                  onChange={onChangeVerifiedAccount}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item container pb={2}>
            <Grid item pb={1}>
              <Label>アカウント状態</Label>
            </Grid>
            <Grid item container xs={12} alignItems={'center'}>
              <Grid item>
                <Checkbox
                  id={'account_status-ID'}
                  ref={register('account_status')}
                  name={'account_status'}
                  checked={isCheckedAccStatus === 1 || false}
                  label={''}
                  pl={3}
                  onChange={accStatusOnChange}
                />
              </Grid>
              <Grid item>
                <Label>有効</Label>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} pb={1}>
            <SubHeading bold="true">スキル</SubHeading>
          </Grid>
          <Grid item xs={12} pb={1}>
            <SubHeading bold="true">所有資格</SubHeading>
          </Grid>
          <Grid item xs={12} pb={2}>
            <Label>取得済みの資格にチェックを入れてください</Label>
          </Grid>
          {workerSkills &&
            workerSkills.map((value, index) => (
              <Grid
                item
                xs={12}
                md={workerSkills.length - 1 === index ? 12 : 6}
                key={index}
                className={'SkillsWrapper_' + index}
              >
                {getInputMultiCheckbox(
                  value?.name,
                  'skills',
                  value?.skills,
                  workerSkills.length - 1 === index ? '手順書があれば可能な作業にチェックをいれてください' : ''
                )}
              </Grid>
            ))}
          <Grid item xs={12} className="WorkExperience-Wrapper">
            {getInputTextAreaField(
              '業務経験',
              '',
              'work_experience',
              '例:\nIT業界経験は通算で12年。\n以前の職場ではシステム運用部に所属し、ヘルプデスク業務従事。\n社員からのOS、Officeソフトについての問い合わせ対応や、PC導入時の設定作業、ハード故障等トラブル対応を行う。\n転職後は取引先から依頼を受け、新規導入PCのキッティング（およそ300台）を経験。\nまた同職場では、自社サイトの更新作業や、全国の月例所長WEB会議のセッティングなども担当した。\nその後個人事業主として独立し、某メーカーの訪問サポートを担う企業から業務委託を受け、\nNASの保守対応や個人宅インターネット導入時訪問サポートに従事。\n並行して、スポットではあるが法人向けITサポート対応（主にPCリプレース）も別企業より受託している。',
              227,
              true
            )}
            <FormHelperText className="WorkExperience-Note">最大1000文字まで入力ください</FormHelperText>
          </Grid>
        </Grid>
        <Grid container pt={4} justifyContent={{ xs: 'center', sm: 'end' }}>
          <LargeButton id="WorkerSubmitBtn" type="submit" color="primary">
            {loader && <CircularProgress size="1.5rem" color="white" />}
            {!loader && '確認'}
          </LargeButton>
        </Grid>
      </form>
    </StyledWorkerCreateUpdateForm>
  )
}

WorkerCreateUpdateForm.displayName = 'Worker Create/Update Form'

WorkerCreateUpdateForm.propTypes = {
  match: PropTypes.any,
  location: PropTypes.any,
}

export default WorkerCreateUpdateForm
